<template>
	<withoutFooter class="without_footer">
		<div class="double_header">
			<div class="container">
				<div class="logo" @click="goToHome"></div>
			</div>
		</div>
		<div class="check-out-page">
			<div class="order_summary">
				<div class="half_container left">
					<div class="head">Order Summary</div>

					<div class="wrap_skeleton" v-if="loaderBasket">
						<v-skeleton-loader
							type="image"
							class="item_shop_skeleton"
							v-for="index in 8"
							:key="index"
						></v-skeleton-loader>
					</div>

					<div class="nothing_data" v-if="!loaderBasket && shoppingCart && !shoppingCart.items.length"></div>

					<div class="wrapper_list" v-if="!loaderBasket && shoppingCart && shoppingCart.items.length">
						<div class="item" v-for="item of shoppingCart.items" :key="item.product_id">
							<div class="left_info">
								<div class="image">
									<template v-if="item.product_type === 'music_release'">
										<div @click="redirectToAlbum(item.product_id, item.identifier)">
											<img :src="item.image" alt="">
										</div>
									</template>
									<template v-if="item.product_type === 'song'">
										<img :src="item.image" alt="">
									</template>
								</div>
								<div class="description">
									<template v-if="item.product_type === 'music_release'">
										<div @click="redirectToAlbum(item.product_id, item.identifier)" class="name">
											{{ item.title }}
										</div>
									</template>
									<div v-else class="name">{{ item.title }}</div>

									<div class="artist" v-if="item.artist.id">
										by
										<div class="album_artist" @click="redirectToArtistPage(item.artist.id, item.artist.identifier)">
											{{ item.artist.name }}
										</div>
									</div>
								</div>
							</div>
							<div class="right_price">
								<span class="price">${{ item.price }}</span>
								<button class="remove_item" @click.stop="remove(item.product_id, item.product_type)" v-ripple>Remove</button>
							</div>
						</div>
					</div>

				</div>

			</div>
			<div class="payment_form" >
				<div class="half_container right" v-if="!loaderBasket && shoppingCart && shoppingCart.items.length">
					<div class="wrap_form">
						<div class="head">Payment</div>
						<template v-if="!redirectStatus">
							<div class="wrap_total">
								<div class="row_total">
									<div class="label">Subtotal</div>
									<div class="value" v-if="shoppingCart && shoppingCart.total">${{ shoppingCart.total }}</div>
								</div>
								<div class="row_total">
									<div class="label">Tax</div>
									<div class="value">$ -</div>
								</div>
								<div class="row_total summary">
									<div class="label">Total</div>
									<div class="value">$ -</div>
								</div>
							</div>

							<template v-if="stripeOptions.clientSecret">
								<stripe-element-payment
									ref="paymentRef"
									:pk="stripeToken"
									:elements-options="stripeOptions"
									:confirm-params="confirmParams"
									:cancel-url="cancelURL"
									:redirect="redirect"
									:key="stripeElementKey"
                                    @error="submitPaymentCallback"
								/>
								<!-- <circleLoader :load-complete="loading === false" v-if="loading === true"></circleLoader> -->
								<div class="actions_form">
									<button type="button" @click.once="submitPayment" class="pay" v-ripple :class="{'loading': loading}" :disabled="loading">
										<v-progress-circular :width="2" indeterminate></v-progress-circular>
										Pay
									</button>
								</div>
							</template>

						</template>
						<template v-else>
							<div v-if="redirectStatus === 'succeeded'">succeeded</div>
						</template>

					</div>
				</div>
			</div>
		</div>
	</withoutFooter>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import withoutFooter from "@/layouts/withoutFooter.vue";
import {StripeElementPayment} from '@vue-stripe/vue-stripe';
export default {
	name: 'checkOut',
	components: {
		withoutFooter,
		StripeElementPayment,
	},
	data() {
		return {
			stripeToken: process.env.VUE_APP_STRIPE_API_TOKEN,
			stripeElementKey: 0,
			stripeOptions: {
				clientSecret: null,
				appearance: {
					variables: {
						fontSizeBase: '16px',
						colorText: '#ffffff',
						colorDanger: '#E94E4E',
						borderRadius: '5px',
						colorBackground: '#0C1324',
						colorIconTabSelected: '#ffffff',
					},
					rules: {
						'.Tab': {
							padding: '12px 12px 7px 12px',
							border: 'none',
							backgroundColor: '#182034',
							boxShadow: 'none'
						},
						'.Tab--selected, .Tab--selected:focus': {
							border: '1px solid #5F6986',
							boxShadow: 'none',
						},
						'.TabLabel': {
							color: '#ffffff',
						},
						'.Input': {
							boxShadow: 'none',
						},
						'.Input--invalid': {
							boxShadow: 'none',
							border: '1px solid #E94E4E'
						},
						'.Input:focus': {
							boxShadow: 'none',
							border: '1px solid #5F6986'
						},

						'.Input::placeholder': {
							color: '#7481A6',
						},
						'.Error': {
							fontSize: '12px',
							marginLeft: '5px'
						}
					}
				},
			},
			confirmParams: {
				return_url: `${window.location.protocol}//${window.location.host}/download-purchased`
			},
			redirect: "if_required",
			cancelURL: `${window.location.protocol}//${window.location.host}/check-out`,
			stripeElements: '',
			loaderShoppingCart: false,
			loaderPayment: false,
			redirectStatus: null,
			loading: false,
			clickPaymentButton: false,
		};
	},

	computed: {
		...mapGetters({
			visibleShoppingCart: 'HEADER_SHOPPING_CART',
			shoppingCart: 'BASKET_LIST',
			updateBasket: 'UPDATE_BASKET',
			loaderBasket: 'LOADER_BASKET',
			purchaseClientSecret: 'PURCHASE_CLIENT_SECRET',
			centrifuge: 'CENTRIFUGE_CLIENT',
			authData: 'AUTH_DATA'
		}),
	},
	beforeMount() {

	},
	created() {
		this.createCardForm();
		this.getDataCart();
		this.getCentrifuge()
			.then(() => {
				const self = this;
				const sub = this.centrifuge.newSubscription(`webhook_purchase_${this.authData.userId}`);
				sub.on('publication', function (ctx) {
					self.getCartItemsCount();
					if (ctx.data.status === 'success') {
						self.$store.commit('SET_REFRESH_RELEASES', true);
						self.$router.push({name: 'downloadPurchased'});
					}
				}).subscribe();

				this.centrifuge.connect();
			}).catch(err => console.error(`Error: checkOut - getCentrifuge, ${err}`));
	},
	mounted() {
		this.redirectStatus = this.$route.query.redirect_status;
	},
	beforeDestroy() {

	},
	methods: {
		...mapActions({
			getBasket: 'GET_BASKET',
			removeFromBasket: 'REMOVE_FROM_BASKET',
			getPurchaseClientSecret: 'GET_PURCHASE_CLIENT_SECRET',
			getCentrifuge: 'GET_CENTRIFUGE_CLIENT',
            getBasketCount: 'GET_BASKET_COUNTER'
		}),
		redirectToAlbum(albumID, albumName) {
			// const albumTitle = albumName.trim().replace(/ /g, '-');
			this.$router.push({name: 'albumDetails', params: {id: albumID, name: window.toHumanFormat(albumName)}});
		},
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		goToHome() {
			this.$router.push({name: 'explore'});
		},
		getDataCart() {
			this.loaderShoppingCart = true;
			this.getBasket()
				.catch(err => console.error(`Error: getDataCart, ${err}`))
				.finally(() => this.loaderShoppingCart = false);
		},
        getCartItemsCount() {
            this.loaderShoppingCart = true;
            this.getBasketCount()
                .catch(err => console.error(`Error: getCartItemsCount, ${err}`))
                .finally(() => {this.loaderShoppingCart = false})
        },
		remove(id, type) {
			let index = this.shoppingCart.items.findIndex((el) => el.product_id === id);
			if (index !== -1) {
                let removedPrice = this.shoppingCart.items[index].price;
				this.shoppingCart.items.splice(index, 1);

				if (!this.shoppingCart.items.length) {
					this.$store.commit('SET_HEADER_SHOPPING_CART', false);
					this.$router.go(-1);
					// this.$router.push({name: 'explore'});
				}

                this.shoppingCart.total -= removedPrice;
			}
			let album = this.$store.getters['FULL_ALBUM'];
			if (album && album.id === id) {
				album.inCart = false;
			}
			if (album.playList && album.playList.length) {
				for (let track of album.playList) {
					if (track.song.id === id) {
						track.song.inCart = false;
					}
				}
			}

			let data = {
				productId: id,
				type: type
			};
			this.removeFromBasket(data)
                .then(() => this.createCardForm())
                .catch(err => console.error(`Error: shippingCart - remove, ${err}`));
		},

		changeStripeClientSecret(clientSecret) {
			if (this.stripeOptions.clientSecret !== clientSecret) {
				this.stripeElementKey += 1;
				this.stripeOptions.clientSecret = clientSecret;
			}
		},

        submitPaymentCallback() {
            this.loading = false;
        },

		async createCardForm() {
			this.getPurchaseClientSecret()
				.then(() => {
					if (this.purchaseClientSecret) {
						this.changeStripeClientSecret(this.purchaseClientSecret)
					}
				});
		},
		async submitPayment() {
			this.$refs.paymentRef.submit();
            this.loading = true;
		},

	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/global/mixins';

//$paddingTop: 90px;
//$width: 50%;
//
//#stripe-payment-element-errors {
//	color: #E94E4E;
//	font-size: 12px;
//	margin-left: 5px;
//}
//
//.double_header {
//	position: absolute;
//	top: 0;
//	left: 0;
//	height: 60px;
//	z-index: 500;
//	width: 100%;
//
//	> .container {
//		> .logo {
//			width: 300px;
//			height: 100%;
//			cursor: pointer;
//		}
//	}
//}
//
//.without-footer-layout {
//	width: 100%;
//	height: 100%;
//	//height: 100vh;
//	position: absolute;
//	top: 0;
//	left: 0;
//	z-index: 100;
//	background: #031020;
//	//padding-top: $smallHeaderPadding;
//}
//
//.check-out-page {
//	display: flex;
//	min-height: 100vh;
//
//	.half_container {
//		width: 665px;
//		height: 100%;
//		display: flex;
//		flex-direction: column;
//
//		&.left {
//			padding-left: 20px;
//			padding-right: 40px;
//		}
//
//		&.right {
//			padding-right: 20px;
//			padding-left: 70px;
//		}
//
//		.head {
//			font-size: 24px;
//			color: #fff;
//			padding-bottom: 11px;
//			border-bottom: 1px solid rgba(#5F6986, .5);
//			margin-bottom: 10px;
//		}
//	}
//
//	.order_summary {
//		display: flex;
//		width: $width;
//		justify-content: flex-end;
//		padding-top: $paddingTop;
//		background: #031020;
//
//		.nothing_data {
//			width: 530px;
//			height: 90px;
//			margin-top: 30px;
//		}
//
//		.wrap_skeleton {
//			width: 100%;
//			height: 620px;
//
//			.item_shop_skeleton {
//				width: 100%;
//				height: 55px;
//				margin-bottom: 4px;
//				background: #182034;
//				border-radius: 6px;
//
//				.v-skeleton-loader__image {
//					height: 100%;
//					background: transparent;
//				}
//			}
//		}
//
//
//		.wrapper_list {
//			//height: calc(100vh - 350px);
//			//overflow-y: auto;
//			//overflow-x: hidden;
//
//
//			&::-webkit-scrollbar {
//				height: 8px;
//			}
//
//			&::-webkit-scrollbar-thumb {
//				background: #20C4F5;
//				border-radius: 5px;
//			}
//
//			&::-webkit-scrollbar-track {
//				background: #182034;
//				border-radius: 5px;
//			}
//		}
//
//		.item {
//			display: flex;
//			justify-content: space-between;
//			align-items: center;
//			height: 55px;
//			padding: 0 20px 0 16px;
//			transition: all 0.18s ease-out;
//			border-radius: 6px;
//			background-color: rgba(#5F6986, .1);
//			position: relative;
//			&:not(:last-child) {
//				margin-bottom: 4px;
//			}
//			&:last-child {
//				margin-bottom: 20px;
//			}
//
//			.left_info {
//				display: flex;
//
//				.image {
//					display: flex;
//					flex-shrink: 0;
//					width: 55px;
//					height: 55px;
//					border-radius: 10px;
//					overflow: hidden;
//					margin-right: 10px;
//					cursor: pointer;
//				}
//
//				.description {
//					display: flex;
//					flex-direction: column;
//					justify-content: center;
//					color: #dcdcdc;
//					padding-right: 15px;
//					font-size: 16px;
//					@include truncateString();
//
//					.name {
//						font-size: 16px;
//						line-height: 1;
//						max-width: 400px;
//						color: #dcdcdc;
//						transition: 0.15s;
//						height: 18px;
//						@include truncateString();
//						cursor: pointer;
//
//						&:hover {
//							color: #fff;
//						}
//					}
//
//					.artist {
//						font-size: 14px;
//						color: #7481A6;
//						display: flex;
//
//						> .album_artist {
//							color: #7481A6;
//							max-width: 300px;
//							padding-left: 3px;
//							@include truncateString();
//							cursor: pointer;
//						}
//					}
//				}
//			}
//
//			.right_price {
//				display: flex;
//				align-items: center;
//				justify-content: flex-end;
//				flex-shrink: 0;
//				width: 90px;
//				position: absolute;
//				right: 0;
//				height: 100%;
//
//				.price {
//					font-size: 16px;
//					color: #dcdcdc;
//					transition: 0.18s;
//					opacity: 1;
//					padding-right: 40px;
//				}
//
//				.remove_item {
//					display: flex;
//					align-items: center;
//					justify-content: center;
//					border: 1px solid #8591B1;
//					border-radius: 22px;
//					width: 91px;
//					height: 34px;
//					font-size: 14px;
//					color: #fff;
//					transition: 0.18s;
//					opacity: 0;
//					position: absolute;
//					right: 20px;
//					top: 11px;
//				}
//			}
//
//			&:hover {
//				background-color: rgba(#5F6986, .2);
//
//				.price {
//					opacity: 0;
//				}
//
//				.remove_item {
//					opacity: 1;
//				}
//			}
//		}
//	}
//
//	.payment_form {
//		padding-top: $paddingTop;
//		display: flex;
//		width: $width;
//		justify-content: flex-start;
//		background: #282C47;
//		position: relative;
//		z-index: 2;
//
//		.wrap_form {
//			width: 460px;
//			position: relative;
//			//height: calc(100vh - 290px);
//			padding-right: 20px;
//			//overflow-y: auto;
//			//overflow-x: hidden;
//
//			.wrap_total {
//				border-bottom: 1px solid rgba(#5F6986, .3);
//				padding: 10px 0 18px 0;
//				margin-bottom: 20px;
//
//				.row_total {
//					display: flex;
//					justify-content: space-between;
//					align-items: center;
//					//margin-bottom: 3px;
//					.label {
//						font-size: 16px;
//						color: #dcdcdc;
//						line-height: 19px;
//					}
//
//					.value {
//						font-size: 16px;
//						color: #fff;
//					}
//
//					&.summary {
//						.label {
//							font-weight: 700;
//							font-size: 20px;
//						}
//
//						.value {
//							font-weight: 700;
//							font-size: 20px;
//						}
//					}
//				}
//			}
//
//			.actions_form {
//				padding-bottom: 20px;
//				border-top: 1px solid rgba(#5F6986, .3);
//				margin-top: 20px;
//
//				.pay {
//					display: flex;
//					justify-content: center;
//					align-items: center;
//					width: 100%;
//					height: 48px;
//					background: #5F6986;
//					box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
//					border-radius: 50px;
//					color: #fff;
//					font-size: 18px;
//					font-weight: 500;
//					margin-top: 20px;
//
//					&.loading {
//						font-size: 0;
//					}
//				}
//			}
//
//			.circle_loader {
//				position: absolute;
//				top: 50%;
//				left: 50%;
//				margin-left: -50px;
//				margin-top: -50px;
//			}
//
//		}
//
//	}
//}
</style>
