<template>
	<div>

    <button class="user" id="header-user-menu 1" v-ripple @click="showUserMenu" v-click-outside="closeMenu"
            :class="{
            click: HEADER_USER_MENU && mainMenu,
            active: (PROFILE && PROFILE.photo || PROFILE && PROFILE.photo === null)
    }">
      <template v-if="PROFILE_LOADER">
        <v-skeleton-loader
          class="mx-auto border"
          max-width="40"
          type="card-avatar"
        ></v-skeleton-loader>
      </template>

      <template v-else-if="!PROFILE_LOADER">
        <img v-if="PROFILE_LOGO['40x40']" :src="`${PROFILE_LOGO['40x40']}?cache=${cacheKey}`" v-bind:srcset="`${PROFILE_LOGO['40x40']}?cache=${cacheKey} 1x, ${PROFILE_LOGO['40x40@2x']}?cache=${cacheKey} 2x`" alt="user avatar">
        <div v-else class="default_user_avatar"></div>
      </template>
    </button>

    <div class="wrapper_user_menu mobile" :class="{'close-menu': closeAllMenu}" v-if="HEADER_USER_MENU === true">
        <div class="user_menu" :class="{show: mainMenu, hide: !dashboardMenu && !accountMenu}">
            <div class="menu_box user_link arrow" v-ripple @click="redirectToProfile()" v-if="!PROFILE_LOADER">
                <div class="user_ava">
                    <img v-if="PROFILE_LOGO['40x40']" :src="`${PROFILE_LOGO['40x40']}?cache=${cacheKey}`" v-bind:srcset="`${PROFILE_LOGO['40x40']}?cache=${cacheKey} 1x, ${PROFILE_LOGO['40x40@2x']}?cache=${cacheKey} 2x`" alt="user avatar">
                    <div v-else class="default_user_avatar"></div>
                </div>
                <div class="description_ava">
                    <template v-if="PROFILE && PROFILE.fullName">{{ PROFILE.fullName }}</template>
                    <p>See profile</p>
                </div>
            </div>

            <div class="menu_box list_links arrow" @click="goToProfile('library')">
                <router-link :to="{name: 'library'}" v-ripple class="i-music">Library</router-link>
            </div>
            <div class="menu_box list_links arrow calendar">
                <button type="button" class="i-ticket" id="events-calendar-show-coming-soon">Calendar</button>
            </div>
            <div class="menu_box list_links arrow"  @click="goToProfile('following')">
                <router-link :to="{name: 'following'}" v-ripple class="i-heart">Following</router-link>
            </div>
            <div class="dashboard_list">Dashboard
            <div class="dashboard">
                <div class="menu_box list_links disabled dashboard_item mobile-hide">
                    <button type="button" class="disabled i-overview" id="overview-show-coming-soon">Overview</button>
                </div>
                <div class="menu_box list_links audience dashboard_item">
                    <router-link :to="{name: 'manageFollowers'}" v-ripple class="i-followers">Followers</router-link>
                </div>
            </div>
            <div class="dashboard">
                <div class="menu_box list_links dashboard_item">
                    <router-link :to="{name: 'accounts'}" v-ripple class="i-accounts">Accounts</router-link>
                </div>
                <div class="menu_box list_links dashboard_item">
                    <router-link :to="{name: 'uploads'}" v-ripple class="i-uploads">Uploads</router-link>
                </div>
            </div>
            <div class="dashboard">
                <div class="menu_box list_links disabled">
<!--                    <router-link :to="{name: 'manageEvents'}" v-ripple class="i-events">Events</router-link>-->
                    <button type="button" class="disabled i-events" >Events</button>
                </div>
                <div class="menu_box list_links disabled">
<!--                    <router-link :to="{name: 'earnings'}" v-ripple class="i-earnings">Earnings</router-link>-->
                    <button type="button" class="disabled i-earnings" >Earnings</button>
                </div>
            </div>
            <div class="dashboard">
                <div class="menu_box list_links disabled">
<!--                    <router-link :to="{name: 'merch'}" v-ripple class="i-merch">Merch</router-link>-->
                    <button type="button" class="disabled i-merch" >Merch</button>
                </div>
                <div class="menu_box list_links disabled">
<!--                    <router-link :to="{name: 'orders'}" v-ripple class="i-orders">Orders</router-link>-->
                    <button type="button" class="disabled i-orders" >Orders</button>
                </div>
                <div class="menu_box list_links disabled">
<!--                    <router-link :to="{name: 'promotions'}" v-ripple class="i-promotions">Promotions</router-link>-->
                    <button type="button" class="disabled i-promotions" >Promotions</button>
                </div>
            </div>
            </div>
            <div class="settings_list">Settings
                <div class="menu_box list_links arrow settings_item">
                    <router-link :to="{name: 'security'}" v-ripple class="i-security">Security</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item mobile-hide">
                    <router-link :to="{name: 'privacy'}" v-ripple class="i-privacy">Privacy</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item mobile-hide">
                    <router-link :to="{name: 'notifications'}" v-ripple class="i-notifications">Notifications</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item">
                    <router-link :to="{name: 'payments'}" v-ripple class="i-payments">Payments</router-link>
                </div>
                <div class="menu_box list_links arrow settings_item">
                    <router-link :to="{name: 'subscriptions'}" v-ripple class="i-subscriptions">Subscriptions</router-link>
                </div>
            </div>

            <button type="button" class="menu_box account_logout" @click="logout">Log out</button>
        </div>
    </div>

		<comingSoonDialog v-if="comingSoonDialogVisible" v-model="comingSoonDialogVisible"></comingSoonDialog>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import comingSoonDialog from "@/components/dialogs/comingSoonDialog";
import eventBus from "@/eventBus";

export default {
    name: 'userMenuMobile',
    props: ['hideMenu'],
    components: {
        comingSoonDialog
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            userAvatarDefault: false,
            comingSoonDialogVisible: false,

            closeAllMenu: true,
            mainMenu: false,
            dashboardMenu: false,
            accountMenu: false,
            cacheKey: new Date().getTime(),
        };
    },
    watch: {
        'AUTH_DATA.accessToken'() {
            this.GET_PROFILE();
        },
        '$route'(to, from) {
            this.closeMenu();
        },
        CHANGE_USER_AVA() {
            this.userAvatarDefault = false;
            this.GET_PROFILE().then(() => {
                this.$store.commit('SET_CHANGE_USER_AVA', false);
                this.cacheKey += 1;
            })
        },
        userMenu() {
            if (!this.userMenu || this.userMenu) {
                this.dashboardMenu = false;
                this.accountMenu = false;
            }
        },
        HEADER_USER_MENU() {
            if (this.HEADER_USER_MENU === true) {
                this.$store.commit('SET_HEADER_SHOPPING_CART', false);
            }
        },
        mainMenu() {
            if (this.mainMenu) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },
    computed: {
        ...mapGetters(['userID', 'AUTH_DATA', 'PROFILE', 'PROFILE_LOADER', 'YOUR_ARTISTS', 'CHANGE_USER_AVA', 'HEADER_USER_MENU', 'PROFILE_LOGO']),
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions(['GET_PROFILE', 'GET_YOUR_ARTISTS']),

        redirectToProfile() {
            if (this.$router.history.current.name !== 'private-profile') {
                this.$router.push({name: 'private-profile'});
            }
        },

        goToProfile(routeName) {
            this.$router.push({name: routeName});
        },

        showUserMenu() {
            this.$store.commit('SET_HEADER_USER_MENU', true);
            this.mainMenu = !this.mainMenu;

            this.closeAllMenu = false;
            if (this.accountMenu || this.dashboardMenu) {
                this.accountMenu = false;
                this.dashboardMenu = false;
            }

            // Emit event to update isShowMobileMenu in the parent component
            eventBus.emit('showMobileMenu', this.mainMenu);
        },

        closeMenu(event) {
            if (event && event.target) {
                const hasClass = event.target.classList.contains('disabled');
                if (hasClass) {
                    event.preventDefault();
                    return;
                }

                if (event.target.id === 'events-calendar-show-coming-soon' || event.target.id === 'overview-show-coming-soon') {
                    this.comingSoonDialogVisible = true;
                }
                if (event.target.id !== 'btn-open-dashboard' && event.target.id !== 'btn-open-main-menu' && event.target.id !== 'btn-open-account-settings') {
                    this.closeAllMenu = true;
                    this.$store.commit('SET_HEADER_USER_MENU', false);
                }
                if (event.target.id !== 'btn-open-dashboard') {
                    this.dashboardMenu = false;
                }
                if (event.target.id !== 'btn-open-main-menu') {
                    this.mainMenu = false;
                }
                if (event.target.id !== 'btn-open-account-settings') {
                    this.accountMenu = false;
                }
            }

            // Emit event to update isShowMobileMenu in the parent component
            eventBus.emit('showMobileMenu', this.mainMenu);
        },

        logout() {
            this.$store.commit('SET_NEED_LOGIN_REDIRECT_PATH', '');

            // Reset the mobile menu state before logging out
            this.mainMenu = false;
            eventBus.emit('showMobileMenu', false); // Ensure the mobile menu is closed

            this.$store.dispatch('LOGOUT').then(() => {
                this.$router.push({name: 'logged-out'});
            });
        },
    }
}
</script>

<style lang="scss">
.slide-right-enter-active,
.slide-right-leave-active {
	transition: transform 0.1s ease-in;
}

.slide-right-enter,
.slide-right-leave-to {
	transform: translateX(-50px);
}

//////////////////////
.slide-y-enter-active,
.slide-y-leave-active {
	transition: transform 1s;
}

.slide-y-enter,
.slide-y-leave-to {
	transform: translateY(-10%);
}

.v-overlay-transition {
	transition: none !important;
}

header .right_box nav ul.right_nav > li .user.active.click {
    background-color: #1D273A;
    position: relative;
    &:before {
        content: ">";
        font-size: 14px;
        transform: scaleX(2) rotate(-90deg);
        position: absolute;
        left: 46%;
        top: 60%;
        line-height: 0;
        letter-spacing: 0;
        width: 0;
        height: 0;
    }
    img {
        display: none;
    }

    .default_user_avatar {
      display: none;
    }
}

.wrapper_user_menu.mobile {
    @media (max-width: 481px) {
        .user_menu {
            .mobile-hide {
                display: none;
            }
            .i-music, .i-ticket, .i-heart {
                background-image: none;
            }

            .calendar {
                display: none;
            }
            .menu_box:not(.settings_item, .dashboard_item) {
                &:not(.user_link) {
                    > * {
                        width: fit-content;
                        margin: 0 auto;
                        padding: 0 !important;
                    }
                    &.disabled {
                        display: none;
                    }

                    &.audience {
                        margin-left: 0;
                    }
                }
                &.arrow {
                    &:after {
                        display: none;
                    }
                }
            }

            .menu_box {
                &:not(.user_link) {
                    &.disabled {
                        display: none;
                    }

                    &.audience {
                        margin-left: 0;
                    }
                }

              &.list_links {
                 > a, button {
                   height: 54px;
                   font-size: 20px;
                 }
              }

              &.account_logout {
                margin-top: 15px!important;
              }
            }

          .dashboard_list {
            .dashboard {
              .menu_box {
                > a, button {
                  font-size: 16px;
                }
              }
            }
          }

          .settings_list {
            padding-bottom: 5px;
            .menu_box {
              > a, button {
                font-size: 18px;
              }
            }
          }

        }

      .description_ava {
        font-size: 20px !important;
        p {
          font-size: 16px !important;
        }
      }
    }

    .user_menu {
        position: fixed;
        margin: 66px 0 0 0;
        height: calc(100vh - 70px);
        background: #090f1d;
        width: 100vw;
        max-width: 100vw;
        overflow: scroll;
        padding: 20px;

        .menu_box {
            background-color: #22293A;
          border-radius: 12px !important;
            &.account_logout {
                background-color: transparent;
                border: 1px solid #374058;
                margin-bottom: 160px;
            }
            &.user_link {
                background: none;
                margin: 0 -20px;
                height: 80px;
                border-bottom: 1px solid #374058;
                border-radius: 0 !important;
                margin-bottom: 15px;

                &.arrow {
                  &:after {
                    content: ">";
                    display: block;
                    font-size: 18px;
                    line-height: 0;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: scaleY(1.5);
                  }
                }

                .user_ava {
                    width: 60px;
                    height: 60px;
                    & > img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .description_ava {
                    p {
                        color: #7481A6;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .list_links a,button {
            padding-left: 55px;
            background-position: 15px center;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.015em;
            text-align: left;
        }

        .list_links {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 10px;
        }
        .dashboard_list {
            margin-top: 17px;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.03em;
            text-align: left;

            .dashboard:first-child {
                margin-top: 4px;
            }
        }
        .dashboard {
            display: flex;
            margin: 0px 0;
            & > div {
                flex-grow: 1;
                flex-basis: 0;
                margin-left: 10px;
                &:first-child {
                    margin-left: 0;
                }
                &.disabled {
                    opacity: 0.6;
                    background: #828491;
                    content: none;
                }
            }
            & a,button {
                align-items: self-end;
                padding: 0 10px;
                background-position: 10px 0;
                height: 52px;
            }

            .menu_box {
                border-radius: 12px;
                > * {
                    margin-top: 12px;
                    margin-bottom: 12px;
                    background-position: 0px 0;
                    margin-left: 15px;
                    padding: 0;
                }
            }
        }
    }
    .arrow {
        position: relative;
        &:after {
            content: ">";
            display: block;
            font-size: 18px;
            line-height: 0;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: scaleY(1.5);
        }
    }
    .settings_list {
        border-bottom: 2px solid #374058;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0.03em;
        text-align: left;

        .menu_box {
            &:first-child {
                margin-top: 4px;
            }
            & {
            background-color: #22293A;
            }
        }
    }
    .i-music {
        background-image: url(~@/assets/img/music.svg);
    }
    .i-ticket {
        background-image: url(~@/assets/img/ticket.svg);
    }
    .i-heart {
        background-image: url(~@/assets/img/heart-16.svg);
    }
}
</style>
