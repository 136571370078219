import axios from 'axios';

const initialState = () => ({
    notifications: [],
});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        SET_NOTIFICATIONS: (state, data) => {
            state.notifications = data;
        },
    },
    actions: {
        GET_NOTIFICATIONS({ commit }, params) {
            return axios.get(`user_notifications`, {params: params})
                .then(response => {
                    commit('SET_NOTIFICATIONS', response.data["hydra:member"]);
                })
                .catch(err => console.error(`GET_NOTIFICATIONS ${err}`));
        },

    },
    getters: {
        NOTIFICATIONS: state => state.notifications,
    }
}