import axios from 'axios';

const initialState = () => ({
    activeReq: null,

    createRecordLabelError: false,
    uniqueLabelName: true,

    recordLabel: '',
    recordLabels: '',
    yourRecordLabels: '',
    countYourRecordLabels: '',
    yourRecordLabelsLoader: false,

    recordLabelFollowers: '',
    selectedLabel: null,
    changeSelectedLabel: false,

    recordLabelsReleases: '',
    recordLabelsReleasesLoader: false,
    hasFollowedLabel: false,

    recordLabelClientSecret: '',
    uncancelRecordLabelSubscriptionStatus: false,
    createRecordLabelSubscriptionLoader: false,
    renewRecordLabelSubscriptionLoader: false,

});

export default {
    state: initialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, initialState());
        },
        // create record label
        SET_CREATE_RECORD_LABEL_ERROR: (state, err) => {
            state.createRecordLabelError = err;
        },
        SET_CHECK_UNIQUE_LABEL_NAME: (state, data) => {
            state.uniqueLabelName = data;
        },
        SET_RECORD_LABEL_CLIENT_SECRET: (state, data) => {
            state.recordLabelClientSecret = data;
        },

        // get record label
        SET_RECORD_LABEL: (state, data) => {
            state.recordLabel = data;
        },
        SET_RECORD_LABEL_FOLLOWERS: (state, data) => {
            state.recordLabelFollowers = data;
        },

        SET_RECORD_LABELS: (state, data) => {
            state.recordLabels = data['hydra:member'];
        },
        SET_YOUR_RECORD_LABELS: (state, data) => {
            state.countYourRecordLabels = data.count_results;
            state.yourRecordLabels = data.items;
        },
        SET_YOUR_RECORD_LABELS_LOADER: (state, data) => {
            state.yourRecordLabelsLoader = data;
        },

        SET_SELECTED_LABEL: (state, data) => {
            state.selectedLabel = data;
        },
        SET_CHANGE_SELECTED_LABEL: (state, data) => {
            state.changeSelectedLabel = data;
        },

        SET_RECORD_LABELS_RELEASES: (state, data) => {
            state.recordLabelsReleases = data;
        },
        SET_RECORD_LABELS_RELEASES_LOADER: (state, data) => {
            state.recordLabelsReleasesLoader = data;
        },
        SET_HAS_FOLLOWED_LABEL: (state, data) => {
            state.hasFollowedLabel = data;
        },

        // subscriptions
        SET_UNCANCEL_RECORD_LABEL_SUBSCRIPTION_STATUS: (state, data) => {
            state.uncancelRecordLabelSubscriptionStatus = data;
        },
        SET_CREATE_RECORD_LABEL_SUBSCRIPTION_LOADER: (state, data) => {
            state.createRecordLabelSubscriptionLoader = data;
        },
        SET_RENEW_RECORD_LABEL_SUBSCRIPTION_LOADER: (state, data) => {
            state.renewRecordLabelSubscriptionLoader = data;
        }
    },
    actions: {
        CREATE_RECORD_LABEL({commit}, params) {
            return axios.post(`record_labels`, params)
                .then(response => {
                    commit('SET_RECORD_LABEL_CLIENT_SECRET', response.data.result.clientSecret);
                })
                .catch(err => {
                    commit('SET_CREATE_RECORD_LABEL_ERROR', true);
                    console.error(`CREATE_RECORD_LABEL ${err}`);
                });
        },
        CHECK_UNIQUE_LABEL_NAME({commit, state}, data) {
            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.post(`record_labels/check-unique-title`, data, {signal}, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    if (response.data.result === true) {
                        commit('SET_CHECK_UNIQUE_LABEL_NAME', true);
                    } else {
                        commit('SET_CHECK_UNIQUE_LABEL_NAME', false);
                    }
                })
                .catch(err => {
                    if (err.response.data.error === 'You already have artist with this name') {
                        commit('SET_CHECK_UNIQUE_LABEL_NAME', false);
                    }
                    console.error(`CHECK_UNIQUE_LABEL_NAME ${err.response.data.error}`)
                });
        },
        GET_RECORD_LABEL({commit}, name) {
            return axios.get(`record_labels/${name}`)
                .then(response => {
                    commit('SET_RECORD_LABEL', response.data);
                })
                .catch(err => console.error(`GET_RECORD_LABEL ${err}`));
        },
        GET_RECORD_LABEL_FOLLOWERS({commit}, id) {
            return axios.get(`record_labels/${id}/followers`)
                .then(response => {
                    commit('SET_RECORD_LABEL_FOLLOWERS', response.data);
                    console.log('recordLabelFollowers', response.data);
                })
                .catch(err => console.error(`GET_RECORD_LABEL_FOLLOWERS ${err}`));
        },
        GET_HAS_FOLLOWED_RECORD_LABEL({commit}, labelID) {
            commit('SET_HAS_FOLLOWED_LABEL', false);
            return axios.get(`record_labels/${labelID}/has-follow`)
                .then(response => {
                    commit('SET_HAS_FOLLOWED_LABEL', response.data.result);
                })
                .catch(err => console.log(`GET_HAS_FOLLOWED_RECORD_LABEL ${err}`));
        },

        FOLLOW_RECORD_LABEL({commit}, id) {
            return axios.post(`record_labels/${id}/follow`)
                .then(response => {
                    return response.data.result;
                })
                .catch(err => {
                    console.error(`FOLLOW_RECORD_LABEL ${err}`);
                    throw err;
                });
        },
        UNFOLLOW_RECORD_LABEL({commit}, id) {
            return axios.post(`record_labels/${id}/unfollow`)
                .then(response => {
                    return response.data.result;
                })
                .catch(err => {
                    console.error(`UNFOLLOW_RECORD_LABEL ${err}`);
                    throw err;
                });
        },
        CHANGE_RECORD_LABEL({commit}, params) {
            return axios.patch(`record_labels/${params.id}`, params, {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then(response => {
                    console.log('CHANGE_RECORD_LABEL', response);
                })
                .catch(err => console.error(`CHANGE_RECORD_LABEL ${err}`));
        },


        GET_RECORD_LABELS({commit, state}, params) {
            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get(`record_labels`, {signal, params: params})
                .then(response => {
                    console.log('recordLabels', response.data);
                    commit('SET_RECORD_LABELS', response.data);
                })
                .catch(err => console.error(`GET_RECORD_LABELS ${err}`));
        },
        GET_YOUR_RECORD_LABELS({commit, state}, params) {
            commit('SET_YOUR_RECORD_LABELS_LOADER', true);

            if (state.activeReq) state.activeReq.abort();
            state.activeReq = new AbortController();
            let signal = state.activeReq.signal;

            return axios.get(`record_labels`, {signal, params: params})
                .then(response => {
                    console.log('recordLabels', response.data);
                    commit('SET_YOUR_RECORD_LABELS', response.data);
                })
                .catch(err => console.error(`GET_YOUR_RECORD_LABELS ${err}`))
                .finally(() => {
                        if (!signal.aborted) {
                            commit('SET_YOUR_RECORD_LABELS_LOADER', false)
                        }
                });
        },
        GET_RECORD_LABEL_RELEASES({commit}, id) {
            commit('SET_RECORD_LABELS_RELEASES_LOADER', true);
            return axios.get(`record_labels/${id}/releases`)
                .then(response => {
                    commit('SET_RECORD_LABELS_RELEASES', response.data.items);
                })
                .catch(err => console.error(`GET_RECORD_LABELS_RELEASES ${err}`))
                .finally(() => commit('SET_RECORD_LABELS_RELEASES_LOADER', false));
        },

        // subscriptions
        CREATE_RECORD_LABEL_SUBSCRIPTION({commit}, labelID) {
            commit('SET_CREATE_RECORD_LABEL_SUBSCRIPTION_LOADER', true);
            return axios.post(`record_labels/${labelID}/subscriptions`)
                .then((response) => {
                    commit('SET_RECORD_LABEL_CLIENT_SECRET', response.data.result.clientSecret);
                })
                .catch(err => console.log(`CREATE_RECORD_LABEL_SUBSCRIPTION ${err}`))
                .finally(() => {
                    commit('SET_CREATE_RECORD_LABEL_SUBSCRIPTION_LOADER', false);
                })
        },
        UNCANCEL_RECORD_LABEL_SUBSCRIPTION({commit}, data) {
            return axios.patch(`record_labels/${data.labelID}/subscriptions/${data.subscriptionID}`)
                .then((response) => {
                    commit('SET_UNCANCEL_RECORD_LABEL_SUBSCRIPTION_STATUS', response.data.result);
                    return response.data.result;
                })
                .catch(err => console.log(`UNCANCEL_RECORD_LABEL_SUBSCRIPTION ${err}`));
        },
        RENEW_RECORD_LABEL_SUBSCRIPTION({commit}, data) {
            commit('SET_RENEW_RECORD_LABEL_SUBSCRIPTION_LOADER', true);
            return axios.patch(`record_labels/${data.labelID}/subscriptions/${data.subscriptionID}`)
                .then((response) => {
                    commit('SET_RECORD_LABEL_CLIENT_SECRET', response.data.result.clientSecret);
                })
                .catch(err => console.log(`RENEW_RECORD_LABEL_SUBSCRIPTION ${err}`))
                .finally(() => {
                    commit('SET_RENEW_RECORD_LABEL_SUBSCRIPTION_LOADER', false);
                })
        },
        REMOVE_RECORD_LABEL_SUBSCRIPTION({commit}, data) {
            return axios.delete(`record_labels/${data.labelID}/subscriptions/${data.subscriptionID}`)
                .then((response) => {
                    return response.data.result;
                })
                .catch(err => console.log(`REMOVE_RECORD_LABEL_SUBSCRIPTION ${err}`));
        },
    },
    getters: {
        CREATE_RECORD_LABEL_ERROR: state => state.createRecordLabelError,
        UNIQUE_LABEL_NAME: state => state.uniqueLabelName,

        // get record label
        RECORD_LABEL: state => state.recordLabel,
        RECORD_LABELS: state => state.recordLabels,
        YOUR_RECORD_LABELS: state => state.yourRecordLabels,
        YOUR_RECORD_LABELS_LOADER: state => state.yourRecordLabelsLoader,
        COUNT_RECORD_LABELS: state => state.countYourRecordLabels,

        RECORD_LABEL_FOLLOWERS: state => state.recordLabelFollowers,

        SELECTED_LABEL: state => state.selectedLabel,
        CHANGE_SELECTED_LABEL: state => state.changeSelectedLabel,

        RECORD_LABEL_RELEASES: state => state.recordLabelsReleases,
        RECORD_LABEL_RELEASES_LOADER: state => state.recordLabelsReleasesLoader,
        HAS_FOLLOWED_RECORD_LABEL: state => state.hasFollowedLabel,

        // subscriptions
        RECORD_LABEL_CLIENT_SECRET: state => state.recordLabelClientSecret,
        UNCANCEL_RECORD_LABEL_SUBSCRIPTION_STATUS: state => state.uncancelRecordLabelSubscriptionStatus,
        CREATE_RECORD_LABEL_SUBSCRIPTION_LOADER: state => state.createRecordLabelSubscriptionLoader,
        RENEW_RECORD_LABEL_SUBSCRIPTION_LOADER: state => state.renewRecordLabelSubscriptionLoader,

    }
}
