<template>
    <div class="albums_list" :class="{'discovery_mode' : DISCOVERY}">
        <template v-if="defer(3)">
            <div class="head_grid_albums" v-if="VIEW_ALBUMS === 'grid' && STATUS_FILTER === true">
                <div class="select_view_albums">
                    <button type="button" v-ripple class="view_list" @click="changeVisibleAlbums('list')" :class="{'active': VIEW_ALBUMS === 'list'}"></button>
                    <button type="button" v-ripple class="view_grid" @click="changeVisibleAlbums('grid')" :class="{'active': VIEW_ALBUMS === 'grid'}"></button>
                </div>
                <div class="sortable">
                    <span>Sort By</span>
                    <v-menu
                        :close-on-content-click="true"
                        offset-y
                        nudge-left="0"
                        nudge-bottom="-3"
                        origin="top center"
                        content-class="yankees">
                        <template v-slot:activator="{ on, attrs }">
                            <button type="button" class="sort_by_btn" v-bind="attrs" v-on="on" aria-role="list">
                                <template v-if="currentOrderBy.sort === 'title'">Title</template>
                                <template v-if="currentOrderBy.sort === 'artist.name'">Artist</template>
                                <template v-if="currentOrderBy.sort === 'releasedAt'">Release Date</template>
                                <template v-if="currentOrderBy.sort === 'dateLiked' && UPPER_FILTER === 'favorite'">Date Liked</template>
                                <span></span>
                            </button>
                        </template>
                        <v-list class="sort_by_list">
                            <template v-if="UPPER_FILTER !== 'favorite'">
                                <v-list-item v-for="(item, index) in sortByAlbums" :key="index">
                                    <button type="button" @click="selectSortBy(item.value)">
                                        {{ item.name }}
                                    </button>
                                </v-list-item>
                            </template>
                            <template v-if="UPPER_FILTER === 'favorite'">
                                <v-list-item v-for="(item, index) in sortByFavoriteAlbums" :key="index">
                                    <button type="button" @click="selectSortBy(item.value)">
                                        {{ item.name }}
                                    </button>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <headListAlbums v-if="VIEW_ALBUMS === 'list' && STATUS_FILTER === true"
                            :selectViewAlbums="true"
                            :stateViewAlbums="VIEW_ALBUMS"
                            :updateViewAlbums="'SET_VIEW_ALBUMS'"
                            :stateOrderBy="'ORDER_BY'"
                            :updateOrderBy="'UPDATE_ORDER_BY'"></headListAlbums>
        </template>

        <div class="wrap_albums" :class="[VIEW_ALBUMS, {disabled: !USER_PERMISSIONS || USER_PERMISSIONS === 'free'}]"
             v-if="UPPER_FILTER !== 'popular' && UPPER_FILTER !== 'trending'">
            <!-- GRID ALBUMS-->
            <template v-if="VIEW_ALBUMS === 'grid'">
                <template v-if="EXPLORE_ALBUMS_LOADING === true || FAVOURITE_MUSIC_RELEASE_LOADING === true">
                    <albumGridSkeletonLoader v-for="index in 30" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                </template>
                <template v-if="EXPLORE_ALBUMS_LOADING === false && FAVOURITE_MUSIC_RELEASE_LOADING === false">
                    <albumGrid
                        v-for="album of UPPER_FILTER !== 'favorite' ? EXPLORE_GRID_ALBUMS : FAVOURITE_MUSIC_RELEASE"
                        :key="album.id"
                        :album="album"
                        :discoveryStatus="DISCOVERY"
                        :playOnlyLikedTracks="UPPER_FILTER === 'favorite'"
                        :isFavouriteSong="UPPER_FILTER !== 'favorite' ? null : 'favourite'"
                        :playZone="UPPER_FILTER !== 'favorite' ? EXPLORE_GRID_ALBUMS : FAVOURITE_MUSIC_RELEASE"></albumGrid>
                </template>
            </template>

            <!-- LIST ALBUMS-->
            <template v-if="VIEW_ALBUMS === 'list'">
                <template v-if="EXPLORE_ALBUMS_LOADING === true || FAVOURITE_MUSIC_RELEASE_LOADING === true">
                    <albumListSkeletonLoader :count="25" :parent="'default_list_albums'"></albumListSkeletonLoader>
                </template>

                <div class="this_is_premium" v-if="!USER_PERMISSIONS || USER_PERMISSIONS === 'free'">
                    <span>List view is a premium feature</span>
                    <router-link :to="{name: 'memberships'}" class="c2a_btn">
                        Upgrade
                        <span class="arrow">
                            <span class="line_svg"></span>
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            </svg>
                        </span>
                    </router-link>
                </div>

                <transition-group class="group-list-album" :class="{disabled: !USER_PERMISSIONS || USER_PERMISSIONS === 'free'}" name="slide"
                                  v-if="EXPLORE_ALBUMS_LOADING === false && FAVOURITE_MUSIC_RELEASE_LOADING === false">
                    <template>
                        <albumList
                            v-for="(album, index) of UPPER_FILTER !== 'favorite' ? EXPLORE_GRID_ALBUMS : FAVOURITE_MUSIC_RELEASE"
                            :key="album.id"
                            :albumDetails="album"
                            :view="albumExpanded[album.id] ? '' : 'short'"
                            @changeVisibilityStatus="changeVisibilityStatus"
                            :discoveryStatus="DISCOVERY"
                            :index="index"
                            :playOnlyLikedTracks="UPPER_FILTER === 'favorite'"
                            :isFavouriteSong="UPPER_FILTER !== 'favorite' ? null : 'favourite'"
                            :playZone="UPPER_FILTER !== 'favorite' ? EXPLORE_GRID_ALBUMS : FAVOURITE_MUSIC_RELEASE"
                            :albumLocation="UPPER_FILTER !== 'favorite' ? 'EXPLORE_GRID_ALBUMS': 'FAVOURITE_MUSIC_RELEASE'"></albumList>
                    </template>
                </transition-group>
            </template>
        </div>

        <template v-if="UPPER_FILTER === 'popular' || UPPER_FILTER === 'trending'">
            <nothingHereYet></nothingHereYet>
        </template>
        <template v-if="UPPER_FILTER === 'pending' || UPPER_FILTER === 'undiscovered' || UPPER_FILTER === 'explore'">
            <nothingHereYet v-if="EXPLORE_ALBUMS_LOADING === false && !EXPLORE_GRID_ALBUMS.length"></nothingHereYet>
            <pagination v-if="EXPLORE_GRID_ALBUMS.length && COUNT_EXPLORE_GRID_ALBUMS > 30"
                        :count="COUNT_EXPLORE_GRID_ALBUMS"
                        :currentPage="ALBUMS_CURRENT_PAGE"
                        :commit="'SET_ALBUMS_CURRENT_PAGE'"
                        @pageChanged="handlePageChange"
                        @scrollTop="scrollTop"></pagination>
        </template>
        <template v-if="UPPER_FILTER === 'favorite'">
            <template v-if="FAVOURITE_MUSIC_RELEASE_LOADING === false">
                <nothingHereYet v-if="!FAVOURITE_MUSIC_RELEASE.length"></nothingHereYet>
                <pagination v-if="FAVOURITE_MUSIC_RELEASE.length && COUNT_FAVOURITE_MUSIC_RELEASE > 30"
                            :count="COUNT_FAVOURITE_MUSIC_RELEASE"
                            :currentPage="FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE"
                            :commit="'SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE'"
                            @pageChanged="handlePageChange"
                            @scrollTop="scrollTop"></pagination>
            </template>
        </template>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Defer from '@/utils/defer';

import albumGrid from './albumGrid';
import headListAlbums from "./headListAlbums";
import albumList from './album';
import pagination from "@/components/pagination";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";
import albumListSkeletonLoader from "@/components/small/skeleton-loaders/albumListSkeletonLoader";
import nothingHereYet from "@/components/small/nothingHereYet";

export default {
    name: 'albumsList',
    components: {
        headListAlbums,
        albumList,
        albumGrid,
        pagination,
        albumGridSkeletonLoader,
        albumListSkeletonLoader,
        nothingHereYet
    },
    mixins: [
        Defer(),
    ],
    data() {
        return {
            loader: false,
            albumExpanded: {},
            selectSortByValue: '',
            sortByFavoriteAlbums: [
                {name: 'Title', value: 'title'},
                {name: 'Released', value: 'releasedAt'},
                {name: 'Date Liked', value: 'dateLiked'},
            ],
            sortByAlbums: [
                {name: 'Title', value: 'title'},
                {name: 'Artist', value: 'artist.name'},
                {name: 'Released', value: 'releasedAt'},
            ],
        }
    },
    computed: {
        ...mapGetters([
            'AUTH_DATA',
            'USER_PERMISSIONS',
            'REFRESH_RELEASES',
            'STATUS_FILTER',
            'VIEW_ALBUMS',
            'ALBUM_EXPANDED',
            'MUSIC_RELEASE', 
            'COUNT_MUSIC_RELEASE', 
            'ALBUMS_CURRENT_PAGE', 
            'SEARCH_ALBUMS_RESULT',
            'EXPLORE_GRID_ALBUMS', 
            'COUNT_EXPLORE_GRID_ALBUMS',
            'ORDER_BY',
            'SEARCH_STRING',
            'UPPER_FILTER',
            'SUBGENRE',
            'TAGS',
            'DISCOVERY',
            'PLAYED_MUSIC_RELEASE',
            'EXPLORE_ALBUMS_LOADING',
            'ALBUM_STATUS',
            'FAVOURITE_MUSIC_RELEASE',
            'COUNT_FAVOURITE_MUSIC_RELEASE',
            'FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE',
            'FAVOURITE_MUSIC_RELEASE_LOADING',
            'TRACK_SCROLL_ID',
            'FAVORITE_TAB_ORDER',
            'LAST_REQUEST_COMPLETED',
            'LAST_REQUEST_CANCELED',
            'FAVORITE_CHANGES_FLAG'
        ]),
        currentOrderBy() {
            return this.UPPER_FILTER === 'favorite' ? this.FAVORITE_TAB_ORDER : this.ORDER_BY;
        }
    },
    watch: {
        'SUBGENRE.id'() {
            console.log('watch SUBGENRE.id - albumsList', this.SUBGENRE.id);
            this.CLEAN_EXPLORE_GRID_ALBUMS();
            this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
            console.log('[Favorite] Setting FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE to 1');
            this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
            this.$store.commit('SET_ALBUM_EXPANDED', '');
            this.selectAlbums();
        },
        TAGS() {
            console.log('TAGS changed');
            this.CLEAN_EXPLORE_GRID_ALBUMS();
            this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
            console.log('[Favorite] Setting FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE to 1');
            this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
            this.selectAlbums();
        },
        ORDER_BY(newValue, oldValue) {
            console.log('ORDER_BY changed');
            if (this.$store.state.exploreFilters.isUserInitiatedOrderBy) {
                console.log('User-initiated ORDER_BY change detected');
                this.CLEAN_EXPLORE_GRID_ALBUMS();
                this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
                console.log('[Favorite] Setting FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE to 1');
                this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
                this.selectAlbums();
            } else {
                console.log('Programmatic ORDER_BY change detected');
            }
            this.$store.commit('SET_USER_INITIATED_ORDER_BY', false);
        },
        DISCOVERY() {
            console.log('DISCOVERY changed');
            this.CLEAN_EXPLORE_GRID_ALBUMS();
            this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
            console.log('[Favorite] Setting FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE to 1');
            this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
            this.selectAlbums();
        },
        VIEW_ALBUMS() {
            this.$store.commit('SET_ALBUM_EXPANDED', '');
        },
        UPPER_FILTER(newValue, oldValue) {
            console.log(`UPPER_FILTER changed from ${oldValue} to ${newValue}`);
            this.CLEAN_EXPLORE_GRID_ALBUMS();
            this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
            this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);

            if (newValue === 'favorite') {
                console.log('[Favorite] Fetching favorites');
                this.selectAlbums();
            } else {
                this.selectAlbums();
            }
        },
		ALBUM_STATUS() {
            console.log('ALBUM_STATUS changed');
            this.CLEAN_EXPLORE_GRID_ALBUMS();
            this.$store.commit('SET_ALBUMS_CURRENT_PAGE', 1);
            console.log('[Favorite] Setting FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE to 1');
            this.$store.commit('SET_FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE', 1);
            this.selectAlbums();
        },
    },
    mounted() {
        let refreshReleases = this.$store.getters['REFRESH_RELEASES'];
        if (refreshReleases) {
            console.log('[Favorite] Refreshing releases');
            this.selectAlbums();
        }

        if (!this.EXPLORE_GRID_ALBUMS.length && this.UPPER_FILTER !== 'favorite') {
            console.log('[Favorite] No EXPLORE_GRID_ALBUMS, fetching albums');
            this.selectAlbums();
        }
        if (this.UPPER_FILTER === 'favorite' && !this.FAVOURITE_MUSIC_RELEASE.length) {
            console.log('[Favorite] Favorite tab active but no releases, fetching favorite albums');
            this.selectAlbums();
        }
    },
    methods: {
        ...mapActions([
            'GET_MUSIC_RELEASE', 
            'CLEAN_EXPLORE_GRID_ALBUMS', 
            'CLEAN_MUSIC_RELEASE', 
            'GET_FAVOURITE_MUSIC_RELEASE',
            'SEARCH_ALBUMS',
            'GET_EXPLORE_GRID_ALBUMS',
            'GET_PLAYED_MUSIC_RELEASE',
            'UPDATE_FAVORITE_TAB_ORDER'
        ]),
        changeVisibilityStatus(id) {
            console.log('changeVisibilityStatus', id);
            let [album] = Object.keys(this.albumExpanded).filter(expanded => this.albumExpanded[expanded]);
            if (+id !== +album) {
                this.albumExpanded[album] = false;
            }
            this.albumExpanded[id] = !this.albumExpanded[id];
        },
        changeVisibleAlbums(value) {
            this.$store.commit('SET_VIEW_ALBUMS', value);
        },
        selectAlbums() {
            console.log('[Favorite] selectAlbums called');
            const albumPublished = {'status': 'published'};
            const subGenre = {'subGenres': this.SUBGENRE.id};
            const tags = this.TAGS ? {'multiple_search_tags': this.TAGS} : [];
            const page = this.ALBUMS_CURRENT_PAGE ? {page: this.ALBUMS_CURRENT_PAGE} : 1;
            const discovery = this.DISCOVERY ? {'playedMusicReleases': false} : '';
            const limit = {limit: 30};

            const favoriteTags = this.TAGS ? {' multiple_search_musicRelease.tags': this.TAGS} : [];
            const favoritePage = this.FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE ? {page: this.FAVOURITE_MUSIC_RELEASE_CURRENT_PAGE} : 1;

            let params;
            if (!this.UPPER_FILTER) {
                console.log('[Favorite] Setting default UPPER_FILTER to "undiscovered"');
                this.$store.commit('SET_UPPER_FILTER', 'undiscovered');
            }

            switch (this.UPPER_FILTER) {
                case 'pending':
                    this.changeVisibleAlbums('list');
                    params = Object.assign({'status': this.ALBUM_STATUS}, subGenre, tags, limit, page, this.ORDER_BY);
                    break;
                case 'undiscovered':
                    params = Object.assign(albumPublished, subGenre, tags, limit, discovery, page, this.ORDER_BY);
                    break;
                case 'explore':
                    params = Object.assign(albumPublished, subGenre, tags, limit, page, this.ORDER_BY);
                    break;
                case 'popular':
                case 'trending':
                    params = {}
                    break;
                case 'favorite':
                    console.log('[Favorite] Constructing params for favorite tab');
                    params = Object.assign(subGenre, favoriteTags, limit, favoritePage, this.FAVORITE_TAB_ORDER);
                    console.log('[Favorite] Params for favorite tab:', params);
                    break;
            }

            this.loader = true;
            if (this.UPPER_FILTER === 'popular' || this.UPPER_FILTER === 'trending') {
                let noneData = {
                    count_results: 0,
                    items: []
                }
                this.$store.commit('SET_EXPLORE_ALBUMS_LOADING', false);
                this.$store.commit('SET_EXPLORE_GRID_ALBUMS', noneData);
            } else if (this.UPPER_FILTER !== 'favorite') {
                console.log('[Favorite] Fetching non-favorite albums');
                this.GET_EXPLORE_GRID_ALBUMS(params)
                    .then(() => {
                        if (this.VIEW_ALBUMS === 'list') {
                            this.EXPLORE_GRID_ALBUMS.forEach(album => {
                                this.$set(this.albumExpanded, album.id, false);
                            });
                        }
                    })
                    .catch(err => {
                        console.error(`GET_EXPLORE_GRID_ALBUMS, ${err}`);
                    })
                    .finally(() => {
                        this.loader = false;
                    });
            }

            if (this.UPPER_FILTER === 'favorite') {
                console.log('[Favorite] Fetching favorite albums');
                this.GET_FAVOURITE_MUSIC_RELEASE(params)
                    .then(() => {
                        console.log('[Favorite] Favorite albums fetched successfully');
                    })
                    .catch(err => {
                        console.error(`getFavoriteAlbums, ${err}`);
                    })
                    .finally(() => {
                        this.loader = false;
                        console.log('[Favorite] Favorite albums fetch completed');
                    });
            }
            this.$store.commit('SET_REFRESH_RELEASES', false);
        },
        selectSortBy(selectSortByValue) {
            console.log('[Favorite] selectSortBy called with value:', selectSortByValue);

            if (this.UPPER_FILTER === 'favorite') {
                if (this.FAVORITE_TAB_ORDER.sort !== selectSortByValue) {
                    console.log('[Favorite] Updating FAVORITE_TAB_ORDER');
                    this.UPDATE_FAVORITE_TAB_ORDER({
                        sort: selectSortByValue,
                        order: 'desc'
                    });
                    this.selectAlbums();
                }
            } else {
                this.$store.commit('SET_USER_INITIATED_ORDER_BY', true);

                if (this.ORDER_BY.sort !== selectSortByValue) {
                    console.log('[Favorite] Updating ORDER_BY');
                    this.$store.dispatch('UPDATE_ORDER_BY', {
                        sort: selectSortByValue,
                        order: 'desc'
                    });
                }
            }
        },
        scrollTop() {
            this.$nextTick(() => {
                let el = document.getElementById('explore-nav-id') || document.getElementById('explore-head');
                if (el) {
                    console.log('[Favorite] Scrolling to top');
                    el.scrollIntoView({behavior: 'smooth', block: 'start'});
                    window.scrollTo({
                        top: el.offsetTop,
                        behavior: 'smooth'
                    });
                } else {
                    console.log('[Favorite] No scroll target found, scrolling to top of page');
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            });
        },
        handlePageChange(newPage) {
            console.log(`[Favorite] Page changed to ${newPage}`);
            this.selectAlbums();
        },
    },
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: all .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>